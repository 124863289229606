import React from 'react';
import './HomePage.css';
import backgroundImage from './img/bg.png';
import logoImage from './img/logo.png';
import btnFace from './img/btn-face.png';
import btnInsta from './img/btn-insta.png';
import btnZap from './img/btn-zap.png';

const HomePage = () => {
  const phoneNumber = '5511982102515';
  const message = 'Oi, gostaria de mais informações sobre os atendimentos.';
  const encodedMessage = encodeURIComponent(message);
  const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
  return (
    <div className="container" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="flex-home">
        <div className="header">
          <img src={logoImage} alt="Jule Fernandes Logo" className="logo" />
        </div>
        <div>
          <div className="menu">
            <div className="menu-item">Radiestesia Terapêutica</div>
            <div className="menu-item">Reiki</div>
            <div className="menu-item">Florais da Lua</div>
            <div className="menu-item">Lista de Transmissão</div>
            <div className="menu-item"><a href={whatsappURL}>Whatsapp</a></div>
          </div>
        </div>
      </div>
        <div className="footer">
          <img src={btnFace} alt="Facebook" className="social-icon" />
          <img src={btnInsta} alt="Instagram" className="social-icon" />
          <a href={whatsappURL}>
            <img src={btnZap} alt="Whatsapp" className="social-icon" />
          </a>
        </div>
    </div>
  );
};

export default HomePage;