// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCWa23kkwsSoYmiFeDdNn_4SWnjVd0IQDI",
    authDomain: "julesite-999ae.firebaseapp.com",
    projectId: "julesite-999ae",
    storageBucket: "julesite-999ae.appspot.com",
    messagingSenderId: "786174966261",
    appId: "1:786174966261:web:dc8259a6e67396ab9788bc",
    measurementId: "G-NXVH5FQB3K"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
